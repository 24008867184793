import React from "react"
import NavBar, {
  NavItem,
  NavLink,
  Submenu,
  Megamenu,
  ExpandButton,
} from "@ui/navbar"
import Heading from "@ui/heading"
import { getClosest, getSiblings } from "@utils/utilFunctions"

import { MobileMenuWrap } from "./mobilemenu.style"
import { FormattedMessage } from "gatsby-plugin-react-intl"

export const MobileMenu = ({ menuData, headingStyle }) => {
  const removeClassFromChildren = parent => {
    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].tagName === "UL") {
        let child = parent.children[i].children
        for (let j = 0; j < child.length; j++) {
          child[j].classList.remove("submenu-open")
        }
      }
    }
  }

  const onClickHandler = (e, selector) => {
    const target = e.target
    const parentEl = target.parentElement
    if (
      parentEl.classList.contains("menu-expand") ||
      target.classList.contains("menu-expand")
    ) {
      let element = target.classList.contains("icon") ? parentEl : target
      const parent = getClosest(element, selector)
      const parentSiblings = getSiblings(parent)
      parentSiblings.forEach(sibling => {
        sibling.classList.remove("submenu-open")
        removeClassFromChildren(sibling)
      })
      removeClassFromChildren(parent)
      parent.classList.toggle("submenu-open")
    }
  }

 

  return (
    <MobileMenuWrap>
      <NavBar>
        <NavItem
          hasSubmenu={false}
          hasMegamenu={false}
          className="menu-item"
          id={`menu-item-1`}
        >
          <NavLink>Home</NavLink></NavItem>
		  <NavItem
          hasSubmenu={false}
          hasMegamenu={false}
          className="menu-item"
          id={`menu-item-1`}
        >
          <NavLink>About us</NavLink></NavItem>
		  <NavItem
          hasSubmenu={false}
          hasMegamenu={false}
          className="menu-item"
          id={`menu-item-1`}
        >
          <NavLink>contact us </NavLink></NavItem>
          {/* {(submenu || megamenu) && (
								<ExpandButton onClick={(e) => onClickHandler(e, `#menu-item-${menuIndex}`)} />
							)}

							{submenu && (
								<Submenu>
									{submenu.map((subitem, j) => {
										const submenuLevelTwo = subitem.submenu;
										const submenuIndex = j;
										return (
											<NavItem
												key={`submenu-${menu.node.id}-${submenuIndex}`}
												className="menu-item"
												id={`submenu-item-${menuIndex}${submenuIndex}`}>

												<NavLink path={subitem.link}>{subitem.text}</NavLink>
												{submenuLevelTwo && <ExpandButton onClick={(e) => onClickHandler(e, `#submenu-item-${menuIndex}${submenuIndex}`)} />}
												{submenuLevelTwo && (
													<Submenu>
														{submenuLevelTwo.map((subitemLevelTwo, k) => {
															const subsubmenuIndex = k;
															return (
																<NavItem
																	key={`submenu-${menu.node.id}-${submenuIndex}-${subsubmenuIndex}`}
																	className="menu-item"
																	id={`submenu-item-${menuIndex}${submenuIndex}${subsubmenuIndex}`}>
																	<NavLink path={subitemLevelTwo.link}>{subitemLevelTwo.text}</NavLink>
																</NavItem>
															)
														})}
													</Submenu>
												)}
											</NavItem>
										)
									})}
								</Submenu>
							)} */}
          <NavItem
          
            className="menu-item"
            id={`menu-item-41`}
          >
            <NavLink path="/">Services</NavLink>

            <ExpandButton onClick={e => onClickHandler(e, `#menu-item-41`)} />
          </NavItem>

          <Megamenu>
            <NavItem id={`megamenu-2`}>
              <Heading {...headingStyle}>
                <span><FormattedMessage id="engineering_designs" /></span>
                <ExpandButton onClick={e => onClickHandler(e, `#megamenu-2`)} />
              </Heading>

              <Submenu>
                <NavItem >
                  <NavLink path="/">
                    <span><FormattedMessage id="building_design" /></span>
                  </NavLink>
                </NavItem>
				<NavItem >
                  <NavLink path="/">
                    <span><FormattedMessage id="modification_of_the_design_and_rehabilitation_existing_buildings" /></span>
                  </NavLink>
                </NavItem>
				<NavItem >
                  <NavLink path="/">
                    <span><FormattedMessage id="interior_designs" /></span>
                  </NavLink>
                </NavItem>
				<NavItem >
                  <NavLink path="/">
                    <span><FormattedMessage id="rehabilitation_of_heritage_buildings_and_historical_sites" /></span>
                  </NavLink>
                </NavItem>
				
              </Submenu>
            </NavItem>
			<NavItem id={`megamenu-3`}>
              <Heading {...headingStyle}>
                <span><FormattedMessage id="General_technical_advisory_services" /></span>
                <ExpandButton onClick={e => onClickHandler(e, `#megamenu-3`)} />
              </Heading>

              <Submenu>
                <NavItem key={`megasubmenu-1`}>
                  <NavLink path="{megaSubitem.link}">
                    <span><FormattedMessage id="technical_advice" /></span>
                  </NavLink>
                </NavItem>
				<NavItem key={`megasubmenu-1`}>
                  <NavLink path="{megaSubitem.link}">
                    <span><FormattedMessage id="technical_follow_up" /></span>
                  </NavLink>
                </NavItem>
				<NavItem key={`megasubmenu-1`}>
                  <NavLink path="{megaSubitem.link}">
                    <span><FormattedMessage id="training_and_qualifying" /></span>
                  </NavLink>
                </NavItem>
              </Submenu>
            </NavItem>
          </Megamenu>

          {/* {megamenu && (
								<Megamenu>
									{megamenu.map((megaitem, i) => {
										const megaSubmenu = megaitem.submenu;
										const megaIndex = i;
										return (
											<NavItem key={`megamenu-${menu.node.id}-${megaIndex}`} id={`megamenu-${menu.node.id}-${megaIndex}`}>
												<Heading {...headingStyle}>
													<span>{megaitem.title}</span>
													<ExpandButton onClick={(e) => onClickHandler(e, `#megamenu-${menu.node.id}-${megaIndex}`)} />
												</Heading>
												{megaSubmenu && (
													<Submenu>
														{megaSubmenu.map((megaSubitem, i) => {
															return (
																<NavItem key={`megasubmenu-${megaIndex}-${i}`}>
																	<NavLink path={megaSubitem.link}>
																		<span>{megaSubitem.text}</span>
																	</NavLink>
																</NavItem>
															)
														})}
													</Submenu>
												)}
											</NavItem>
										)
									})}
								</Megamenu>
							)} */}
    
      </NavBar>
    </MobileMenuWrap>
  )
}

MobileMenu.defaultProps = {
  headingStyle: {
    fontSize: "14px",
    mb: "0",
    texttransform: "uppercase",
    color: "#fff",
  },
}
