import React from "react";
import { DropmenuWrap } from './dropdown-submenu.style'

const DropdownSubMenu = ({ children }) => {
	return (
		<DropmenuWrap>{children}</DropmenuWrap>
	)
}

export default DropdownSubMenu
