import React from "react"
import PropTypes from "prop-types"
import NavBar, { NavItem, NavLink, Submenu, Megamenu } from "@ui/navbar"
import Heading from "@ui/heading"
import { MainMenuWrap } from "./mainmenu.style"
import {FormattedMessage } from "gatsby-plugin-react-intl"
import "./style.css"
export const MainMenu = ({ headingStyle, menuData, ...props }) => {
 

  return (
    <MainMenuWrap {...props}>
      <NavBar>
        <NavItem>
          <NavLink hassubmenu={true} path="/">
            <span><FormattedMessage id="home" /></span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink path="/about-us">
            <span><FormattedMessage id="about_us" /></span>
          </NavLink>
        </NavItem>


        <NavItem hasSubmenu={true}>
          <NavLink path="/engineering-designs/" >
          <span><FormattedMessage id="design_services" /></span>
            <i className="icon fa fa-angle-down"></i>
          </NavLink>


   
    						<Submenu>
    						
    								
    									<NavItem >
    										<NavLink className="submenu-text" path="/engineering-designs/new-design">
                        <span><FormattedMessage id="building_design" /></span>
    											{/* {hasSubmenuLevelTwo && <i className="icon fa fa-angle-down"></i>} */}
    										</NavLink>
    										
    									</NavItem>

                      <NavItem >
    										<NavLink className="submenu-text" path="/engineering-designs/modifications-reahbititions">
    										<span><FormattedMessage id="modify_project" /></span>
    											{/* {hasSubmenuLevelTwo && <i className="icon fa fa-angle-down"></i>} */}
    										</NavLink>
    										
    									</NavItem>

                      <NavItem >
    										<NavLink className="submenu-text" path="/engineering-designs/interior-designs">
    										<span><FormattedMessage id="interior_designs" /></span>
    											{/* {hasSubmenuLevelTwo && <i className="icon fa fa-angle-down"></i>} */}
    										</NavLink>
    										
    									</NavItem>
                      <NavItem >
    										<NavLink className="submenu-text" path="/engineering-designs/Rehabilitation-heritage-buildings">
    										<span><FormattedMessage id="rehabilitation_of_heritage_buildings_and_historical_sites" /></span>
    											{/* {hasSubmenuLevelTwo && <i className="icon fa fa-angle-down"></i>} */}
    										</NavLink>
    										
    									</NavItem>
    							
    						</Submenu>
                </NavItem>
        <NavItem hasSubmenu={true}>
          <NavLink path="/general-technical-advisory" >
            
            <span><FormattedMessage id="consulting_services" /></span>
            <i className="icon fa fa-angle-down"></i>
          </NavLink>


   
    						<Submenu>
    						
    								
    									<NavItem >
    										<NavLink className="submenu-text" path="/technical-advice/">
    											<span><FormattedMessage id="General_technical_advisory_services" /></span>
    											{/* {hasSubmenuLevelTwo && <i className="icon fa fa-angle-down"></i>} */}
    										</NavLink>
    										
    									</NavItem>

                      <NavItem >
    										<NavLink className="submenu-text" path="/tecknical-follow-up/">
    											<span><FormattedMessage id="technical_follow_up" /></span>
    										
    										</NavLink>
    										
    									</NavItem>

                      <NavItem >
    										<NavLink className="submenu-text" path="/training-and-qualifications/">
    											<span><FormattedMessage id="training_and_qualifying" /></span>
    										
    										</NavLink>
    										
    									</NavItem>
    							
    						</Submenu>

          {/* /* <Megamenu>
            <NavItem className="header-menu-container">
            <NavLink className="link-header-menu" path="/engineering-designs">
              <Heading {...headingStyle}><FormattedMessage id="engineering_designs" /></Heading></NavLink>

              <Submenu>
                <NavItem className="sub-menu-text-container">
                 
                    <NavLink  path="/engineering-designs/new-design" className="submenu-text"><FormattedMessage id="building_design" /></NavLink>
               
                </NavItem>
                <NavItem className="sub-menu-text-container">
                
                    <NavLink path="/engineering-designs/modifications-reahbititions" className="submenu-text"><FormattedMessage id="modification_of_the_design_and_rehabilitation_existing_buildings" /></NavLink>
              
                </NavItem>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink path="/engineering-designs/interior-designs" className="submenu-text"><FormattedMessage id="interior_designs" /></NavLink>
                  
                </NavItem>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink path="/engineering-designs/Rehabilitation-heritage-buildings" className="submenu-text"><FormattedMessage id="rehabilitation_of_heritage_buildings_and_historical_sites" /></NavLink>
               
                </NavItem>
              </Submenu>
            </NavItem>
            <NavItem className="header-menu-container">
            <NavLink path="/general-technical-advisory" className="link-header-menu">  <Heading className="heading-style" {...headingStyle}><FormattedMessage id="General_technical_advisory_services" /></Heading></NavLink>

              <Submenu>
                <NavItem className="sub-menu-text-container">
                 
                    <NavLink path="/technical-advice" className="submenu-text"><FormattedMessage id="technical_advice" /></NavLink>
             
                </NavItem>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink className="submenu-text"><FormattedMessage id="technical_follow_up" /></NavLink>
                 
                </NavItem>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink className="submenu-text"><FormattedMessage id="training_and_qualifying" /></NavLink>
             
                </NavItem>
              </Submenu>
            </NavItem>
            <NavItem className="header-menu-container">
            <NavLink path="/study-request" className="link-header-menu">
              <Heading {...headingStyle}><FormattedMessage id="study_request" /></Heading>
</NavLink>
              <Submenu>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink className="submenu-text"><FormattedMessage id="new_project" /></NavLink>
               
                </NavItem>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink className="submenu-text"><FormattedMessage id="interior_design" /></NavLink>
               
                </NavItem>
                <NavItem className="sub-menu-text-container">
                
                    <NavLink className="submenu-text"><FormattedMessage id="rehabilitation_of_an_existing_project" /></NavLink>
               
                </NavItem>
                <NavItem className="sub-menu-text-container">
              
                    <NavLink className="submenu-text"><FormattedMessage id="garden_studies" /></NavLink>
             
                </NavItem>
                <NavItem className="sub-menu-text-container">
             
                    <NavLink className="submenu-text"><FormattedMessage id="restoration_of_heritage_building_historical_site" /></NavLink>
               
                </NavItem>
              </Submenu>
            </NavItem>
            <NavItem className="header-menu-container">
            <NavLink path="/technical-advice-request" className="link-header-menu">  <Heading {...headingStyle}><FormattedMessage id="technical_advice_request" /></Heading>
            </NavLink> 
              <Submenu>
                <NavItem className="sub-menu-text-container">
                 
                    <NavLink className="submenu-text"><FormattedMessage id="technical_advice" /></NavLink>
                
                </NavItem>
                <NavItem className="sub-menu-text-container">
                
                    <NavLink className="submenu-text"><FormattedMessage id="technical_follow_up_to_the _mplementation" /></NavLink>
               
                </NavItem>
                <NavItem className="sub-menu-text-container">
                
                    <NavLink className="submenu-text"><FormattedMessage id="training_courses_for_newly_graduated_engineers" /></NavLink>
             
                </NavItem>
              </Submenu>
            </NavItem>
          </Megamenu>  */}
        </NavItem>
        <NavItem>
          <NavLink path="/contact-us">
            <span><FormattedMessage id="contact_us" /></span>
          </NavLink>
        </NavItem>
      </NavBar>
    </MainMenuWrap>

    // <MainMenuWrap {...props}>
    // 	<NavBar>
    // 		{menuarr.map(menu => {
    // 			const submenu = menu.node.submenu ? menu.node.submenu : null;
    // 			const megamenu = menu.node.megamenu ? menu.node.megamenu : null;
    // 			return (
    // 				<NavItem key={`mainmenu-${menu.node.id}`} hasSubmenu={submenu} hasMegamenu={megamenu}>
    // 					<NavLink path={menu.node.link} hassubmenu={(submenu || megamenu) ? "true" : "false"}>
    // 						<span>{menu.node.text}</span>
    // 						{(submenu || megamenu) && <i className="icon fa fa-angle-down"></i>}
    // 					</NavLink>
    // 					{submenu && (
    // 						<Submenu>
    // 							{submenu.map((subitem, i) => {
    // 								const hasSubmenuLevelTwo = subitem.isSubmenu;
    // 								const submenuLevelTwo = subitem.submenu;
    // 								const submenuIndex = i;
    // 								return (
    // 									<NavItem key={`submenu-${menu.node.id}-${submenuIndex}`}>
    // 										<NavLink path={subitem.link}>
    // 											<span>{subitem.text}</span>
    // 											{hasSubmenuLevelTwo && <i className="icon fa fa-angle-down"></i>}
    // 										</NavLink>
    // 										{submenuLevelTwo && (
    // 											<Submenu>
    // 												{submenuLevelTwo.map((subitemLevelTwo, j) => {
    // 													const subsubmenuIndex = j;
    // 													return (
    // 														<NavItem key={`submenu-${menu.node.id}-${submenuIndex}-${subsubmenuIndex}`}>
    // 															<NavLink path={subitemLevelTwo.link}>{subitemLevelTwo.text}</NavLink>
    // 														</NavItem>
    // 													)
    // 												})}
    // 											</Submenu>
    // 										)}
    // 									</NavItem>
    // 								)
    // 							})}
    // 						</Submenu>
    // 					)}
    // 					{megamenu && (
    // 						<Megamenu>
    // 							{megamenu.map((megaitem, i) => {
    // 								const megaSubmenu = megaitem.submenu;
    // 								const megaIndex = i;
    // 								return (
    // 									<NavItem key={`megamenu-${menu.node.id}-${megaIndex}`}>
    // 										<Heading {...headingStyle}>{megaitem.title}</Heading>
    // 										{megaSubmenu && (
    // 											<Submenu>
    // 												{megaSubmenu.map((megaSubitem, i) => {
    // 													return (
    // 														<NavItem key={`megasubmenu-${megaIndex}-${i}`}>
    // 															<NavLink path={megaSubitem.link}>
    // 																<span>{megaSubitem.text}</span>
    // 															</NavLink>
    // 														</NavItem>
    // 													)
    // 												})}
    // 											</Submenu>
    // 										)}
    // 									</NavItem>
    // 								)
    // 							})}
    // 						</Megamenu>
    // 					)}
    // 				</NavItem>
    // 			)
    // 		})}
    // 	</NavBar>
    // </MainMenuWrap>
  )
}

MainMenu.propTypes = {
  alignment: PropTypes.string,
  headingStyle: PropTypes.object,
}

MainMenu.defaultProps = {
  alignment: "center",
  headingStyle: {
    fontSize: "14px",
    mb: "20px",
    texttransform: "uppercase",
  },
}
