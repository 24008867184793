import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Container } from "react-bootstrap";
import { Row, Col } from "@ui/wrapper";
import Text from "@ui/text";
import Anchor from '@ui/anchor'
import Heading from "@ui/heading";
import Social, { SocialLink } from '@ui/social'
import { FormattedMessage } from "gatsby-plugin-react-intl"
import  { StyleSheetManager } from "styled-components";
import rtlcss from "stylis-rtlcss";
import logo from "@assets/images/logo/dark-logo.png"
import iconwhats from "@assets/images/icons/whatsapp.png"

import "./style.css"
import {
	FooterWrap,
	FooterTop,
	FooterWidget,
	LogoWidget,
	TextWidget,
	FooterWidgetList,
	FooterBottom
} from './footer.style'

const Footer = ({ copyrightStyle, ...props }) => {
	const siteInfo = useStaticQuery(graphql`
        query FooterSiteQuery {
            site {
                siteMetadata {
                    copyright
                    contact {
                        phone
                        email
                        address
                        website
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
                }
            }
        }      
    `)
	const { phone, email, address, website } = siteInfo.site.siteMetadata.contact;
	const { copyright } = siteInfo.site.siteMetadata;
	const { facebook, twitter, instagram, linkedin } = siteInfo.site.siteMetadata.social;
	return (
		<StyleSheetManager stylisPlugins={[rtlcss]}>
		<FooterWrap {...props}>
			<FooterTop>
				<Container>
					<Row>
						<Col lg={4} sm={6}>
							<FooterWidget responsive={{ medium: { mb: '31px' } }}>
								<LogoWidget className="footer-text">
									
									<img style={{width:"158px"}} src={logo}/>
								</LogoWidget>
								<TextWidget>
									
									
									 <Text className="footer-text" style={{height:"20px"}}  mb="10px"><Anchor path={`tel:${phone}`} fontWeight="800" color="#333" hoverstyle="2">00966556268225</Anchor><img style={{marginRight: "4px",maxHeight:"100%"}} src={iconwhats}/></Text>
									
								</TextWidget>
							</FooterWidget>
						</Col>
						<Col lg={3} md={5} sm={6}>
							<FooterWidget responsive={{ medium: { mb: '31px' } }}>
								<Heading className="heading-footer" as="h6" mt="-3px" mb="20px"><FormattedMessage id="services" /></Heading>
								<FooterWidgetList>
									<li className="footer-text" ><Anchor path="/engineering-designs" color="textColor" hoverstyle="2"><FormattedMessage id="engineering_designs" /></Anchor></li>
									<li className="footer-text"><Anchor path="/technical-advice" color="textColor" hoverstyle="2"><FormattedMessage id="General_technical_advisory_services" /></Anchor></li>
									<li className="footer-text"><Anchor path="/study-request" color="textColor" hoverstyle="2"><FormattedMessage id="study_request" /></Anchor></li>
									<li className="footer-text"><Anchor path="/consultation-request" color="textColor" hoverstyle="2"><FormattedMessage id="technical_advice_request" /></Anchor></li>
									
								</FooterWidgetList>
							</FooterWidget>
						</Col>
						<Col lg={3} md={5} sm={6}>
							<FooterWidget responsive={{ medium: { mb: '27px' } }}>
								<Heading className="heading-footer" as="h6" mt="-3px" mb="20px"><FormattedMessage id="quick_links" /></Heading>
								<FooterWidgetList>
									<li className="footer-text"><Anchor path="/" color="textColor" hoverstyle="2"><FormattedMessage id="home" /></Anchor></li>
									<li className="footer-text"><Anchor path="/about-us" color="textColor" hoverstyle="2"><FormattedMessage id="about_us" /></Anchor></li>
									<li className="footer-text"><Anchor path="/contact-us" color="textColor" hoverstyle="2"><FormattedMessage id="contact_us" /></Anchor></li>
									
								</FooterWidgetList>
							</FooterWidget>
						</Col>
						<Col lg={2} md={4} sm={6}>
							<FooterWidget responsive={{ medium: { mb: '27px' } }}>
								<Heading className="heading-footer" as="h6" mt="-3px" mb="20px"><FormattedMessage id="support" /></Heading>
								<FooterWidgetList>
									
									<li className="footer-text"><Anchor path="/contact-us" color="textColor" hoverstyle="2"><FormattedMessage id="contact_us" /></Anchor></li>
									
								</FooterWidgetList>
							</FooterWidget>
						</Col>
						
						{/* <Col lg={2} md={4} sm={6}>
							<FooterWidget mt='50px' responsive={{ small: { mt: '34px' } }}>
								<FooterWidgetList>
									<li>
										<Button path="/" to="/" imgbutton="true" shadow="true">
											<StaticImage src="../../../assets//images/icons/aeroland-button-google-play.jpg" alt="Google Play" width={162} height={48} />
										</Button>
									</li>
									<li>
										<Button path="/" to="/" imgbutton="true" shadow="true">
											<StaticImage src="../../../assets//images/icons/aeroland-button-app-store.jpg" alt="App Store" width={162} height={48} />
										</Button>
									</li>
								</FooterWidgetList>
							</FooterWidget>
						</Col> */}
					</Row>
				</Container>
			</FooterTop>
			<FooterBottom>
				<Container>
					<Row className="align-items-center">
						<Col md={6} className="text-center text-md-left">
							{<Text>© 2021 Construct Vision.<FormattedMessage id="all_rights_reserved" /> .</Text>}
						</Col>
						<Col md={6} className="text-center text-md-right">
							
						</Col>
					</Row>
				</Container>
			</FooterBottom>
		</FooterWrap>
		</StyleSheetManager>
	)
}

Footer.propTypes = {
	bgcolor: PropTypes.string,
	reveal: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

Footer.defaultProps = {
	bgcolor: '#f6f6f6',
	reveal: 'false',
	copyrightStyle: {
		responsive: {
			small: {
				pb: '15px'
			}
		}
	}
};

export default Footer
