import React, { useState } from "react"
import PropTypes from "prop-types"
import Dropdown from "@ui/dropdown"
import DropdownItem from "@ui/dropdown/dropdown-item"
import DropdownItemInner from "@ui/dropdown/dropdown-item-inner"
import DropdownSubMenu from "@ui/dropdown/dropdown-submenu"
import enFlag from "@assets/images/flags/en.png"
import frFlag from "@assets/images/flags/fr.png"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"

import { LanguageWrap } from "./language.style"

const Language = props => {
  const languageName = {
    en: "en",

    ar: "ar",
  }

  const handleDirection = language => {
    if( typeof document!=='undefined'){
    if (language === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
    }
    if (language === "ar") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
      document.getElementsByTagName("html")[0].setAttribute("lang", "er")
    }}
  }
  const [language, setLanguage] = useState([
    {
      id: 1,
      flag: enFlag,
      name: "en",
      isActive: true,
      path: "en",
    },
    {
      id: 2,
      flag: frFlag,
      name: "ar",
      isActive: false,
      path: "ar",
    },
  ])

  let activeLanguage = language.filter(el => {
    return el.isActive
  })

  return (
    <LanguageWrap {...props}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <Dropdown>
            {}
            <DropdownItem>
              <DropdownItemInner>
                {handleDirection(currentLocale)}
                <img
                  src={activeLanguage[0].flag}
                  alt={activeLanguage[0].name}
                  style={{ maxHeight: "100%", height: "21px" }}
                />
                <span>{currentLocale}</span>
              </DropdownItemInner>
              <DropdownSubMenu>
                {languages.map(language => (
                  <>
                    

                    <DropdownItem
                      key={language}
                      onClick={() => {
                        changeLocale(language)
                        handleDirection(language)
                      }}
                    >
                      <DropdownItemInner>
                        <span>{languageName[language]}</span>
                      </DropdownItemInner>
                    </DropdownItem>
                  </>
                ))}
              </DropdownSubMenu>
            </DropdownItem>
          </Dropdown>
        )}
      </IntlContextConsumer>

      {/* <Dropdown>
				<DropdownItem active>
					<DropdownItemInner active>
						<img src={activeLanguage[0].flag} alt={activeLanguage[0].name} />
						<span>{activeLanguage[0].name}</span>
					</DropdownItemInner>
					<DropdownSubMenu>
						{
							inActiveLanguage.map(lang => {
								const { id, name, flag,path } = lang;
								return (
									<DropdownItem key={id} onClick={() => onClickHandler(id,path)}>
										<DropdownItemInner>
											<img src={flag} alt={name} />
											<span>{location?.pathname}</span>
											<span></span>
										</DropdownItemInner>
									</DropdownItem>
								)
							})
						}
					</DropdownSubMenu>
				</DropdownItem>
			</Dropdown> */}
    </LanguageWrap>
  )
}

Language.propTypes = {
  spacer: PropTypes.object,
}

export default Language
